import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45413330"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "description" }
const _hoisted_3 = { class: "field" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = { class: "field" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "field login-btn" }
const _hoisted_8 = { class: "account-creation" }
const _hoisted_9 = { class: "reset-password-wrapper" }
const _hoisted_10 = { class: "title" }
const _hoisted_11 = { class: "description" }
const _hoisted_12 = { class: "field" }
const _hoisted_13 = ["placeholder", "disabled"]
const _hoisted_14 = { class: "field login-btn" }
const _hoisted_15 = {
  key: 0,
  class: "field"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonPV = _resolveComponent("ButtonPV")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["form-msg-wrapper", [_ctx.isFormMsg ? 'show' : '', _ctx.isError ? 'error' : 'success']])
    }, [
      _createElementVNode("p", null, _toDisplayString(_ctx.formErrorContent), 1)
    ], 2),
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('login.title')), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('login.description')), 1),
    _createElementVNode("form", {
      onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.createRecaptchaToken && _ctx.createRecaptchaToken(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          id: "username",
          type: "text",
          class: _normalizeClass(["inputfield w-full", [_ctx.errors.USERNAME.mandatory ? 'error' : '']]),
          placeholder: 
                        _ctx.errors.USERNAME.mandatory
                            ? _ctx.$t('miscellaneous.error_msgs.required')
                            : _ctx.$t('miscellaneous.placeholders.username')
                    ,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.LOGIN_FORM.USERNAME) = $event))
        }, null, 10, _hoisted_4), [
          [_vModelText, _ctx.LOGIN_FORM.USERNAME]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _withDirectives(_createElementVNode("input", {
          id: "password",
          type: "password",
          class: _normalizeClass(["inputfield w-full", [_ctx.errors.PASSWORD.mandatory ? 'error' : '']]),
          placeholder: 
                        _ctx.errors.PASSWORD.mandatory
                            ? _ctx.$t('miscellaneous.error_msgs.required')
                            : _ctx.$t('miscellaneous.placeholders.password')
                    ,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.LOGIN_FORM.PASSWORD) = $event))
        }, null, 10, _hoisted_6), [
          [_vModelText, _ctx.LOGIN_FORM.PASSWORD]
        ])
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "forgot-password",
        onClick: _cache[2] || (_cache[2] = 
                    () => {
                        _ctx.resetPasswordFormToggle('open')
                        _ctx.resetFormErrors()
                    }
                )
      }, _toDisplayString(_ctx.$t('login.reset_password')), 1),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_ButtonPV, {
          label: "Einloggen",
          class: "button-raised w-full primary",
          type: "submit"
        })
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", null, [
          _createElementVNode("b", null, _toDisplayString(_ctx.$t('login.signup_option.message_1')), 1)
        ]),
        _createElementVNode("ul", null, [
          _createElementVNode("li", null, [
            _createTextVNode(_toDisplayString(_ctx.$t('login.signup_option.option_1_pre')) + " ", 1),
            _createElementVNode("a", {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push('/'))),
              class: "signup-nav"
            }, _toDisplayString(_ctx.$t('miscellaneous.here')), 1),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('login.signup_option.option_1_post')), 1)
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("b", null, _toDisplayString(_ctx.$t('login.signup_option.message_2')), 1)
        ]),
        _createElementVNode("ul", null, [
          _createElementVNode("li", null, [
            _createTextVNode(_toDisplayString(_ctx.$t('login.signup_option.option_2_pre')) + " ", 1),
            _createElementVNode("a", {
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$router.push('verify-email'))),
              class: "signup-nav"
            }, _toDisplayString(_ctx.$t('miscellaneous.here')), 1),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('login.signup_option.option_2_post')), 1)
          ])
        ])
      ])
    ], 32),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", {
        class: _normalizeClass(["form-msg-wrapper", [_ctx.isFormMsg ? 'show' : '', _ctx.isError ? 'error' : 'success']])
      }, [
        _createElementVNode("p", null, _toDisplayString(_ctx.formErrorContent), 1)
      ], 2),
      _createElementVNode("h2", _hoisted_10, _toDisplayString(_ctx.$t('password_reset.reset_password_title_2')), 1),
      _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('password_reset.reset_password_long')), 1),
      _createElementVNode("form", {
        onSubmit: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitResetPassword && _ctx.submitResetPassword(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_12, [
          _withDirectives(_createElementVNode("input", {
            id: "email",
            type: "text",
            class: _normalizeClass(["inputfield w-full", [_ctx.errors.EMAIL.mandatory ? 'error' : '']]),
            placeholder: 
                            _ctx.errors.EMAIL.mandatory
                                ? _ctx.$t('miscellaneous.error_msgs.required')
                                : _ctx.$t('password_reset.your_email')
                        ,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.RESET_PASSWORD_FORM.EMAIL) = $event)),
            disabled: _ctx.isDisable
          }, null, 10, _hoisted_13), [
            [_vModelText, _ctx.RESET_PASSWORD_FORM.EMAIL]
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_ButtonPV, {
            label: _ctx.$t('password_reset.send_instructions'),
            class: "button-raised w-full primary",
            type: "submit",
            disabled: _ctx.isDisable
          }, null, 8, ["label", "disabled"])
        ]),
        (_ctx.isEmailUnknown)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createVNode(_component_ButtonPV, {
                label: _ctx.$t('login.create_new_account'),
                class: "button-raised w-full primary",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$router.push('verify-email')))
              }, null, 8, ["label"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("a", {
          href: "#",
          class: "forgot-password",
          onClick: _cache[8] || (_cache[8] = 
                        () => {
                            _ctx.resetPasswordFormToggle('close')
                            _ctx.resetFormErrors()
                        }
                    )
        }, _toDisplayString(_ctx.$t('password_reset.back_to_login')), 1)
      ], 32)
    ])
  ]))
}